<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >
    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center">
              <b-button
                v-if="!$route.meta.breadcrumb[0].active"
                size="sm"
                variant="outline-primary"
                class="mr-1"
                :to="{
                  name: checkAuthorizeRole(
                    $route.meta.back ? $route.meta.back : 'dashboard'
                  ),
                  query: {
                    tab: $route.meta.tab ? $route.meta.tab : 0
                  }
                }"
              >
                <i class="align-middle ti ti-arrow-left card-icon" />
              </b-button>
              <h2 class="content-header-title float-left pr-1 mb-0">
                {{ t($route.meta.pageTitle) }}
              </h2>
            </div>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item
                  :to="{ name: checkAuthorizeRole('dashboard') }"
                >
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  v-for="item in $route.meta.breadcrumb"
                  :key="item.text"
                  :active="item.active"
                  :to="
                    !item.active
                      ? {
                        name: checkAuthorizeRole(item.to),
                        params: item.params ? item.params : {},
                        query: item.queries ? item.queries : {}
                      }
                      : item.to
                  "
                >
                  {{ t(item.text) }}
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { checkAuthorizeRole } from '@/auth/utils'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BButton,
  },
  setup() {
    const { t } = useI18nUtils()

    return {
      // i18n
      t,
      checkAuthorizeRole,
    }
  },
}
</script>

<style scoped>
.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}
</style>
