var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$route.meta.breadcrumb || _vm.$route.meta.pageTitle)?_c('b-row',{staticClass:"content-header"},[_c('b-col',{staticClass:"content-header-left mb-2",attrs:{"cols":"12","md":"9"}},[_c('b-row',{staticClass:"breadcrumbs-top"},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"d-flex align-items-center"},[(!_vm.$route.meta.breadcrumb[0].active)?_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"outline-primary","to":{
                name: _vm.checkAuthorizeRole(
                  _vm.$route.meta.back ? _vm.$route.meta.back : 'dashboard'
                ),
                query: {
                  tab: _vm.$route.meta.tab ? _vm.$route.meta.tab : 0
                }
              }}},[_c('i',{staticClass:"align-middle ti ti-arrow-left card-icon"})]):_vm._e(),_c('h2',{staticClass:"content-header-title float-left pr-1 mb-0"},[_vm._v(" "+_vm._s(_vm.t(_vm.$route.meta.pageTitle))+" ")])],1),_c('div',{staticClass:"breadcrumb-wrapper"},[_c('b-breadcrumb',[_c('b-breadcrumb-item',{attrs:{"to":{ name: _vm.checkAuthorizeRole('dashboard') }}},[_c('feather-icon',{staticClass:"align-text-top",attrs:{"icon":"HomeIcon","size":"16"}})],1),_vm._l((_vm.$route.meta.breadcrumb),function(item){return _c('b-breadcrumb-item',{key:item.text,attrs:{"active":item.active,"to":!item.active
                    ? {
                      name: _vm.checkAuthorizeRole(item.to),
                      params: item.params ? item.params : {},
                      query: item.queries ? item.queries : {}
                    }
                    : item.to}},[_vm._v(" "+_vm._s(_vm.t(item.text))+" ")])})],2)],1)])])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }